import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import LegalView from './LegalView'
import { H2, P } from '../shared/text'

const Privacy = () => {
  return (
    <LegalView title="Terms & Conditions">
      <P>
        The use of this site is governed by the policies, terms and conditions set forth below, please read them carefully. Your use of this
        site indicates your acceptance of these terms and conditions. Your placement of an order indicates your acceptance of these terms
        and conditions. Your submittal of any purchase order to eatyourgrummies.com indicates acceptance of these terms and conditions.
        These terms and conditions shall supersede any subsequent terms or conditions included with any purchase order, whether or not such
        terms or conditions are signed by eatyourgrummies.com. eatyourgrummies.com reserves the right to make changes to this site and these
        terms and conditions at any time.
      </P>

      <H2>Copyright & Trademark Notice</H2>

      <P>
        This site is owned and operated by eatyourgrummies.com. Unless otherwise specified, all materials appearing on this site, including
        the text, site design, logos, graphics, icons, and images, as well as the selection, assembly and arrangement thereof, are the sole
        property of eatyourgrummies.com, Copyright © 2020, ALL RIGHTS RESERVED. All audio and video clips are licensed by, or are the sole
        property of, eatyourgrummies.com or their respective content providers. All software used on the site is licensed by, or is the sole
        property of, eatyourgrummies.com or those vendors supplying the software. You may use the content of this site only for the purpose
        of shopping on this site or placing an order on this site and for no other purpose. No materials from this site may be copied,
        reproduced, modified, republished, uploaded, posted, transmitted, or distributed in any form or by any means without the prior
        written permission of eatyourgrummies.com. All rights not expressly granted herein are reserved. Any unauthorized use of the
        materials appearing on this site may violate copyright, trademark and other applicable laws and could result in criminal or civil
        penalties. eatyourgrummies.com is a registered trademark, trademark or service mark of eatyourgrummies.com. All custom graphics,
        icons, logos and service names are registered trademarks, trademarks or service marks of eatyourgrummies.com. All other trademarks
        or service marks are property of their respective owners. The use of any eatyourgrummies.com trademark or service mark without the
        express written consent of eatyourgrummies.com is strictly prohibited.
      </P>

      <H2>Disclaimer & Limitation of Liability as to Products Sold</H2>

      <P>
        All of products sold on eatyourgrummies.com are guaranteed against defects for 15 days from the date of the invoice, subject to the
        following conditions. Except as expressly stated herein, eatyourgrummies.com makes no representations or warranties, either express
        or implied, of any kind with respect to products sold on the eatyourgrummies.com web site. Except as expressly stated herein,
        eatyourgrummies.com expressly disclaims all warranties, express or implied, of any kind with respect to products sold on this site,
        including but not limited to, merchantability and fitness for a particular purpose. You agree that the sole and exclusive maximum
        liability to eatyourgrummies.com arising from any product sold on the eatyourgrummies.com site shall be the price of the product
        ordered. In no event shall eatyourgrummies.com, its directors, officers, employees or other representatives be liable for special,
        indirect, consequential, or punitive damages related to any product sold.
      </P>

      <H2>Disclaimer & Limitation of Liability as to eatyourgrummies.com Website</H2>

      <P>
        Eatyourgrummies.com website and the materials therein are provided “as is”. eatyourgrummies.com makes no representations or
        warranties, either express or implied, of any kind with respect to eatyourgrummies.com website, its operation, content, information,
        or materials. eatyourgrummies.com expressly disclaims all warranties, express or implied, of any kind with respect to the sites or
        their use, including but not limited to merchantability and fitness for a particular purpose. You agree that eatyourgrummies.com,
        its directors, officers, employees or other representatives shall not be liable for damages arising from the operation, content or
        use of eatyourgrummies.com website. You agree that this limitation of liability is comprehensive and applies to all damages of any
        kind, including without limitation direct, indirect, compensatory, special, incidental, punitive and consequential damages.
      </P>

      <H2>Typographical Errors</H2>

      <P>
        In the event a product is listed at an incorrect price or with incorrect information due to typographical error or error in pricing
        or product information received from our suppliers, eatyourgrummies.com shall have the right to refuse or cancel any orders placed
        for product listed at the incorrect price. eatyourgrummies.com shall have the right to refuse or cancel any such orders whether or
        not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and
        your order is canceled, eatyourgrummies.com shall immediately issue a credit to your credit card account in the amount of the
        charge.
      </P>

      <H2>Links</H2>

      <P>
        This site may contain links to other sites on the Internet that are owned and operated by third parties. You acknowledge that
        eatyourgrummies.com is not responsible for the operation of or content located on or through any such site.
      </P>

      <H2>Order Acceptance Policy</H2>

      <P>
        Your receipt of an electronic or other form of order confirmation does not signify our acceptance of your order, nor does it
        constitute confirmation of our offer to sell. eatyourgrummies.com reserves the right at any time after receipt of your order to
        accept or decline your order for any reason. eatyourgrummies.com reserves the right at any time after receipt of your order, without
        prior notice to you, to supply less than the quantity you ordered of any item. All orders placed over $250.00(U.S.) are subject to
        pre-approval with an acceptable method of payment, as established by our credit and fraud avoidance department. We may require
        additional verifications or information before accepting any order. eatyourgrummies.com is a reseller to end user customers and does
        not accept orders from exporters, wholesalers, or other customers who intend to resell the products offered by eatyourgrummies.com.
        We reserve the right to substitute out of stock or discontinued items with an item of similar or greater value.
      </P>

      <H2>Return Policy</H2>

      <P>
        At Grummies we are committed to providing you with the highest quality products, and making your health and wellness our number one
        priority. Similarly, all of our products are custom formulated for the individual. For this reason, we maintain strict quality
        control standards and do not re-use or re-sell anything once it ships from our warehouse. This is entirely to ensure your health &
        safety. We hope you'll love our products, but in case you don't, we offer a 30-day money-back guarantee. Please reach out to us with
        your Order ID within 30 days of your received order to begin the returns process. You can contact us using the information below: By
        e-mail:{' '}
        <OutboundLink href="mailto:support@eatyourgrummies.com" target="_blank" rel="noopener noreferrer">
          support@eatyourgrummies.com
        </OutboundLink>
        .
      </P>

      <H2>Sales Tax</H2>

      <P>Each customer shall be solely responsible for all sales taxes, or other taxes, on orders shipped to any other state.</P>

      <H2>Jurisdiction & Venue</H2>

      <P>
        You agree that any legal action brought against eatyourgrummies.com, shall be governed by the laws of the State of Delaware without
        regard to its conflict of law principles. You agree that the sole jurisdiction and venue for any litigation arising from your use of
        or orders made on eatyourgrummies.com website shall be an appropriate Federal or State court located in Delaware. The information
        presented is for informational purposes only. The products and information found on eatyourgrummies.com are not intended to replace
        professional medical advice or treatment. The statements on this site have not been evaluated by the FDA. Dietary supplements,
        including the products on this site, are not intended to diagnose, treat, cure or prevent any disease or medical condition. You
        should not use the information on this site for diagnosis or treatment of any health problem or for prescription of any medication
        or other treatment. Consult with a healthcare professional before taking any of the products on this site, before starting any diet,
        exercise or supplementation program, before taking any medication, or if you have or suspect you might have a health problem.
      </P>
    </LegalView>
  )
}

export default Privacy
