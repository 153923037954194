import * as React from 'react'
import { PageProps } from 'gatsby'

import Layout from '../layouts'

import Terms from '../components/Legal/Terms'
import SEO from '../components/shared/SEO'

const TermsPage = (props: PageProps) => {
  const { location } = props
  return (
    <>
      <SEO title="Terms & Conditions" />
      <Layout location={location}>
        <Terms />
      </Layout>
    </>
  )
}

export default TermsPage
